import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    allData: null
  },
  mutations: {
    SET_DATA(state, payload) {
      state.allData = payload.data
    }
  },
  actions: {
    // 获取数据
    getData({ commit }) {
      // 这里可以写异步请求
      // 假设请求成功后，将数据存储到state中
      console.log(window.location.search.split('token=')[1])
      return fetch('https://tubaobao.ikj360.com/api/data/bigScreen', {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'source': 'pc',        // 平台
            'x-token': window.location.search.split('token=')[1]
        },
        mode: 'cors'
      }).then(res => res.json()).then(res => {
        commit('SET_DATA', { data: res.data })
        return res
      })
    }
  },
  modules: {
  }
})
